/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/js/js/aa/contactAA/router/formRouter.js
*    TO   --> ./legacy-web/src/main/assets/js/contact-aa/router/form-router.js
**/

angular.module('com.aa.contact').config(function ($routeProvider) {
    $routeProvider
        .when("/", {
            templateUrl: function () {
                var defaultTopic = angular.element('#defaultTopic').attr('data-default-topic');
                return "/contact/forms/topic-selection?topic=" + defaultTopic;
            },
            controller: 'MainCtrl'
        })
        .when("/topics/ACS/:subject", {
            templateUrl: function (urlAttr) {
                return "/contact/topics/ACS/" + urlAttr.subject;
            },
            controller: 'MainCtrl'
        })
        .when("/topics/BG/:subject", {
            templateUrl: function (urlAttr) {
                return "/contact/topics/BG/" + urlAttr.subject;
            },
            controller: 'MainCtrl'
        }).when("/topics/CR/:subject/:reason", {
        templateUrl: function (urlAttr) {
            return "/contact/topics/CR/" + urlAttr.subject + "/" + urlAttr.reason;
        },
        controller: 'MainCtrl'
    })
        .when("/topics/TRP/:subject", {
            templateUrl: function (urlAttr) {
                return "/contact/topics/TRP/" + urlAttr.subject;
            },
            controller: 'MainCtrl'
        }).when("/topics/DA/:subject", {
        templateUrl: function (urlAttr) {
            return "/contact/topics/DA/" + urlAttr.subject;
        },
        controller: 'MainCtrl'
    }).when("/topics/ADM/:subject", {
        templateUrl: function (urlAttr) {
            return "/contact/topics/ADM/" + urlAttr.subject;
        },
        controller: 'MainCtrl'
    }).when("/topics/AAV/:subject", {
        templateUrl: function (urlAttr) {
            return "/contact/topics/AAV/" + urlAttr.subject;
        },
        controller: 'MainCtrl'
    }).when("/topics/GMT/:subject", {
        templateUrl: function (urlAttr) {
            return "/contact/topics/GMT/" + urlAttr.subject;
        },
        controller: 'MainCtrl'
    }).when("/topics/TRP/:subject", {
        templateUrl: function (urlAttr) {
            return "/contact/topics/TRP/" + urlAttr.subject;
        },
        controller: 'MainCtrl'
    }).when("/account", {
        templateUrl: function () {
            return "/contact/account";
        },
        controller: 'MainCtrl'
    }).otherwise({redirectTo: '/'});
});
